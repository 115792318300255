import { useState } from "react";
import { FaSearch } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { CircularProgress } from "@mui/material";

function App() {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isparloading1, setParLoading1] = useState(false);
  const [firsttimeshow, setFirstTimeShow] = useState(true);

  const surpriseOptions = [
    "How do you think advancements in artificial intelligence will impact the job market in the next decade?",
    "What are some of the most pressing environmental issues facing the world today, and what can individuals do to help address them?",
    "In what ways do you think technology has changed the way people communicate and form relationships?",
    "What are your thoughts on the ethical implications of genetic engineering and designer babies?",
    "In what ways can we improve access to quality education for underserved communities, especially in regions with limited resources?",
  ];

  const surprise = () => {
    const randomValue = surpriseOptions[Math.floor(Math.random() * surpriseOptions.length)];
    setValue(randomValue);
  }

  const getResponse = async () => {
    debugger;
    setParLoading1(true);
    setFirstTimeShow(false);
    const genAI = new GoogleGenerativeAI("AIzaSyB1-vLu82ps5KJc_flX-_NnYot2qbl2_3w");
    if (!value) {
      setError("Please enter a question!");
      return;
    }
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          history: chatHistory,
          message: value
        }),
      };
      //const baseUrl = "http://localhost:8000";
      //const baseUrl = "http://ai.sslaws.org";
      //const endpoint = "/gemini";
      // const response = await fetch(baseUrl + endpoint, options);
      // const data = await response.text();
      const text_model = genAI.getGenerativeModel({ model: 'gemini-pro' });
      //Answer common legal questions and provide general legal information
      const chat = text_model.startChat({ history: chatHistory });
      const message = value;
      const prompt =
        "You are expert, trained and highly skilled lawyer. Your role is to help other lawyer answer their questions like an expert lawyer with accuracy and in the language and tone that a lawyer can understand. YOu can only answer questions for lawyer not any other questions. Any questions not related to your skill should be politely declined. " +
        message;
      //const prompt = `common legal laws and court related description to ${message} provide title and description with JSON Format`;
      const result = await chat.sendMessage(prompt);
      const response = await result.response;
      const text = response.text().replace(/\*/g, '');

      console.log(text);
      setChatHistory(oldChatHistory => [...oldChatHistory,
      { role: "user", parts: value },
      { role: "model", parts: text }
      ]);

      setValue("");
      setParLoading1(false)
    } catch (error) {
      console.error(error);
      setError("There was an error fetching the data.");
    }
  }

  const clear = () => {
    setValue("");
    setError("");
    setChatHistory([]);
  }

  return (
    <div className="app">
      {/* <div className="chat">
        <div className="chat-bubble">
          <p className="role">Gemini:</p>
          <p className="item">Hi there! What do you want to know?
            <button className="surprise" onClick={surprise} disabled={!chatHistory}>Surprise me!</button>
          </p>
        </div>
      </div> */}
      <div className="search-results">
        <br></br>
        {firsttimeshow ?
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ color: 'white' }}>Need help with the lawyer ?</h2> <br />
            <h4 style={{ color: 'gray' }}>Just type your question below ?</h4>
          </div> : null}
        {chatHistory.map((chatItem, _index) =>
          <div className="chat-item" key={_index}>
            <p className="role">{chatItem.role}</p>
            <p className="parts">{chatItem.parts}</p>
            {/* <div className="parts"
              dangerouslySetInnerHTML={{ __html: chatItem.parts }}
            /> */}
          </div>
        )}
      </div>
      {error && <div className="error-message"><p>{error}</p></div>}
      <div className="input-container" style={{ width: '100%' }}>
        <span className="icon">
          {isparloading1 ? <CircularProgress /> : null}
        </span>
        <input value={value} onChange={(e) => setValue(e.target.value)} type="text" placeholder="" />
        {!error && <button onClick={getResponse}><IoMdSend /></button>}
        {error && <button onClick={clear}>Clear</button>}
      </div>

    </div>
  );
}

export default App;
